/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}
/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 14 px 0;
  background-color: #121212;
}
nav.navbar a.navbar-brand {
    width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/************ Navbar Mobile Sidebar ************/
@media (max-width: 768px) {
  .navbar-collapse {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #121212;
    z-index: 9980;
    overflow-y: auto;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
    display: flex;
    flex-direction: column;
    align-items: center; /* Centraliza conteúdo horizontalmente */
    padding: 20px; /* Espaço interno */
    min-width: 50%; /* Mínimo de 50% da tela */
    max-width: 80%; /* Máximo de 80% da tela */
  }

  .navbar-expand-md .navbar-collapse.show {
    transform: translateX(0);
  }

  .navbar-expand-md .navbar-toggler.collapsed + .navbar-collapse {
    transform: translateX(-100%);
  }

  .mobile-navbar-brand {
    display: block;  /* Somente visível no mobile */
    align-self: center; /* Centraliza a logo horizontalmente */
    margin-top: 20px; /* Espaço acima da logo */
    margin-bottom: 20px; /* Espaço abaixo da logo */
  }

  .desktop-navbar-brand {
    display: none;  /* Esconde a logo do desktop no mobile */
  }

  .social-icon {
    display: flex;
    justify-content: center;
    list-style-type: none; /* Remove os pontos de lista */
    padding: 0;
    margin-top: 20px;
    width: 100%;
  }

  .social-icon a {
    margin: 0 10px; /* Espaçamento entre ícones */
  }

  .navbar-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centraliza o botão 'Fale Conosco' */
    margin-top: 20px;
  }

  .navbar-nav {
    width: 100%; /* Garante que o nav ocupe a largura total */
    justify-content: center; /* Centraliza os links horizontalmente */
  }

  .nav-link {
    text-align: center; /* Centraliza o texto dos links */
  }
}

@media (min-width: 769px) {
  .mobile-navbar-brand {
    display: none;  /* Esconde a logo do mobile no desktop */
  }

  .desktop-navbar-brand {
    display: block;  /* Mostra somente no desktop */
  }
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  position: relative;
  overflow: hidden;
  height: 100vh; /* Ajuste para ocupar a altura total da tela */
  display: flex;
  align-items: center; /* Centraliza verticalmente o conteúdo */
}

.banner .row {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

.video-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /*filter: blur(.2px);*/
  z-index: -1;
}

.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

@keyframes updown {
  0% {
      transform: translateY(-20px);
  }
  50% {
      transform: translateY(20px);
  }
  100% {
      transform: translateY(-20px);
  }
}

.glitch {
  position: relative;
  font-size: 60px;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
  letter-spacing: 5px;
  z-index: 1;
}

.banner h1 {
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7); /* Adiciona sombra preta */
}


/************ About Css ************/
.about {
  position: relative;
  }
.about-bx {
  background: #1f1f1f;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
.about h1 {
  font-size: 3rem;
	font-weight: 700;
  text-align: center;
}
.about p {
    color: white;
    font-size: 1.125rem;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 15px 0 0px 0;
}
.about-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.about-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}


@media (max-width: 600px) {
  .about h1 {
    font-size: 2.55rem; /* Tamanho menor para telas pequenas */
  }

  .about p {
    font-size: 1rem; /* Tamanho menor para telas pequenas */
  }

  .about-bx{
    padding: 40px 20px; /* Espaçamento menor */
  }
}

/************ Projects Css ************/
.project {
  padding: 80px 0 80px;
  position: relative;
}
.project h1 {
	font-size: 3rem;
	font-weight: 700;
  text-align: center;
}
.project p {
  color: #B8B8B8;
  font-size: 1.125rem;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

.members-bx {
  background: #313131;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
}

.members-bx p {
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: start;
  width: 56%;
}

.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 50%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}

.proj-imgbx {
  position: relative;
  border-radius: 40px;
  overflow: hidden;
  margin-bottom: 24px;
  height: 300px; /* Altura fixa do card */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8); /* Sombra leve para destaque */
}

.proj-imgbx img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.4s ease-in-out;
}

.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #6c194b -5.91%, #291873 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 2em;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 1em;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}


@media (max-width: 600px) {
  .project p {
    font-size: 1rem; /* Tamanho menor para telas pequenas */
  }

  .members-bx{
    padding: 20px 0px; /* Espaçamento menor */
  }

  .project-card {
    height: 50vh; /* Altura de 50% da viewport */
  }

  .proj-imgbx {
    height: 200px; /* Altura fixa do card */
  }

  .proj-txtx span{
    font-size: .8em;
  }

  .proj-txtx h4{
    font-size: .9em;
  }
}


/************ Contact Css ************/

.contact-bx {
  background: #FFFFFF;  /* Fundo branco */
  padding: 40px;
  text-align: center;
}

.contact-bx img {
  height: 400px;
  object-fit: contain;
  transition: 0.4s ease-in-out;
  display: block;
  margin: 55px auto;
}

.contact-title {
  font-size: 3rem;
	font-weight: 700;
  text-align: center;
  color: black;
  margin-bottom: 30px; /* Espaçamento reduzido para parecer mais conectado */
}

.contact-subtitle {
  font-size: 1.5rem;
  font-weight: 400;
  color: #333;
  margin-bottom: 0.625rem; /* Espaçamento reduzido para parecer mais conectado */
}

.contact-email {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 0; /* Removido o espaçamento inferior para conexão visual */
}

.email-link {
  color: black; /* Torna o texto preto */
  text-decoration: none; /* Remove sublinhado */
}

.email-link:hover {
  text-decoration: none; /* Adiciona um sublinhado ao passar o mouse para indicar clicabilidade */
}

@media (max-width: 600px) {
  .contact-bx img {
    height: 200px;
    object-fit: contain;
    transition: 0.4s ease-in-out;
    display: block;
    margin: 55px auto;
  }
}

/************ Footer Css ************/
.footer {
  padding: 50px 0;
  background-image: url('./assets/img/footer-bg.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer img {
  width: 26%;
}

.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

@media (max-width: 576px) {
  .footer img {
    margin: 0 auto; /* Centraliza a logo horizontalmente */
    display: block;
  }
  .social-icon {
    margin-top: 20px; /* Adiciona espaço acima dos ícones sociais */
  }
}
